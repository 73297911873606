* {
  outline: none;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Square";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
  background: #27282c;
}

body {
  margin: 0;
  box-sizing: border-box;
}
a,
.MuiLink-root.MuiTypography-root {
  text-decoration: none;
}
.app {
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Square";
  overflow: auto;
  transition: all ease 0.33ms;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 !important;
}

.ohm-card {
  max-width: 833px;
  width: 100%;
  .card-header {
    width: 100%;
    min-height: 33px;
    margin-bottom: 10px;
    h5 {
      font-weight: 600 !important;
    }
  }

  .help-text {
    margin: 10px 0px;
  }

  .data-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 12px 0px;
  }

  .data-row-centered {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 12px 0px;
    .marginedBtn {
      margin: 0.3rem;
    }
  }
}

.ohm-pairs {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

a:hover svg {
  color: inherit;
}

.rightBg {
  width: 496px;
  height: 273px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  .rightBg {
    display: none;
  }
}

.MuiLoadingButton-loadingIndicator {
  position: relative !important;
  right: auto !important;
  margin-left: 10px;
}

a.label-link {
  // color:#c7c8cc;
  color: rgba(0, 112, 215, 1);
  text-decoration: underline;
}

.pull-to-refresh {
  display: flex;
  justify-content: center;
  color: #c7c8cc;
}